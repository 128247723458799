import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mainUrl } from "../costants";
import { getUser, updateUser } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";

type UpdateUserProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
};

const UpdateUser: React.FC<UpdateUserProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //user uid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    email: "",
    name: "",
    address: "",
    city: "",
    province: "",
    postal_code: "",
    phone: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={link}>
        {t(title)}
      </Link>,
      <Typography color="#ffffff" key="3">
        {values.name}
      </Typography>,
    ]);
  }, [values]);

  useEffect(() => {
    getUser(id!).then((res: any) => {
      if (res && res.user) {
        setValues({
          email: res.user?.email,
          name: res.user?.name,
          address: res.user?.user_info?.address,
          city: res.user?.user_info?.city,
          province: res.user?.user_info?.province,
          postal_code: res.user?.user_info?.zip_code,
          phone: res.user?.user_info?.phone,
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    updateUser(id!, {
      email: values.email,
      name: values.name,
      user_info: {
        phone: values.phone,
        address: values.address,
        city: values.city,
        province: values.province,
        zip_code: values.postal_code,
      },
      user_id: id!,
    }).then((res) => {
      if (res && res.result) {
        enqueueSnackbar(t("updateUserSuccess"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("updateUserError"), { variant: "error" });
      }
      console.log("RES", res);
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={values.name} goBack={() => navigate(-1)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("name")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("onlyAddress")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("city")}
                  value={values.city}
                  handleChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("province")}
                  value={values.province}
                  handleChange={(e) =>
                    setValues({ ...values, province: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("zip")}
                  value={values.postal_code}
                  handleChange={(e) =>
                    setValues({ ...values, postal_code: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("phone")}
                  value={values.phone}
                  handleChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  email: "",
                  name: "",
                  address: "",
                  city: "",
                  province: "",
                  postal_code: "",
                  phone: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateUser;
