import { Divider } from "@mui/material";
import React from "react";
import CustomText from "./CustomText";

type CustomDividerProps = {
  label?: string;
  textAlign?: "left" | "center" | "right";
};

const CustomDivider: React.FC<CustomDividerProps> = ({ label, textAlign }) => {
  if (label) {
    return (
      <Divider textAlign={textAlign ? textAlign : "center"}>
        <CustomText type="body2" label={label} />
      </Divider>
    );
  } else {
    return <Divider />;
  }
};

export default CustomDivider;
