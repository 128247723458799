import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dockNumListUrl, infoManagementUrl, mainUrl } from "../costants";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { getDockMode, updateDockMode } from "../../../api/services/dockService";

type UpdateDockModeProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
};

const UpdateDockMode: React.FC<UpdateDockModeProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //dockNum uid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    code: "",
    description: "",
    notes: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={link}>
        {t(title)}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("dockMode") + values.code}
      </Typography>,
    ]);
  }, [values]);

  useEffect(() => {
    getDockMode(id!).then((res: any) => {
      if (res && res.dock_mode) {
        setValues({
            code: res.dock_mode?.code,
            description: res.dock_mode?.description,
            notes: res.dock_mode?.notes,
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    updateDockMode(id!, {
        code: (values.code).toString(),
        description: values.description,
        notes: values.notes,
    }).then((res) => {
      if (res && res.result) {
        enqueueSnackbar(t("updateDockModeSuccess"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("updateDockModeError"), { variant: "error" });
      }
      console.log("RES", res);
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("numDocks") + " " + values.code} goBack={() => navigate(dockNumListUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("notes")}
                  value={values.notes}
                  handleChange={(e) =>
                    setValues({ ...values, notes: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                    code: "",
                    description: "",
                    notes: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateDockMode;
