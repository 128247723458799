import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { settingsUrl, mainUrl } from "../costants";
import { getSettings, updateSettings } from "../../../api/services/settingsService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";

type UpdateSettingsProps = {
    setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
    title: string;
    link: string;
};

const UpdateSettings: React.FC<UpdateSettingsProps> = ({
    setBreadcrumb,
    title,
    link,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [range, setRange] = useState("");

    useEffect(() => {
        setBreadcrumb([
            <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
                {t("home")}
            </Link>,
            <Link style={{ color: "#ffffff" }} key="2" to={link}>
                {t(title)}
            </Link>,
            <Typography color="#ffffff" key="3">
                {t("settings")}
            </Typography>,
        ]);
    }, []);

    useEffect(() => {
        getSettings().then((res: any) => {
            console.log("RES", res);
            if (res && res.settings) {
                setRange(res.settings.range);
            }
            setLoading(false);
        });
    }, [reload]);

    const handleSubmit = () => {
        updateSettings({r: range}).then((res: any) => {
            if (res) {
                enqueueSnackbar(t("settingsUpdated"), {
                    variant: "success",
                });
            }
        });
        setReload(!reload);
    };

    if (loading) {
        return <CustomLoading />;
    }

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ padding: "100px 32px" }}
        >
            <Grid item xs={12}>
                <CustomTitle title={t("movesPerHour")} goBack={() => navigate(mainUrl)} />
            </Grid>
            <Grid item xs={12} md={8}>
                <CustomCard
                    content={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    label={t("range")}
                                    value={range}
                                    handleChange={(e) =>
                                        setRange(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                    }
                    actions={[
                        <CustomButton
                            fullWidth
                            label={t("reset")}
                            onClick={() =>
                                setRange("")
                            }
                            confirm
                            type="outlined"
                        />,
                        <CustomButton
                            fullWidth
                            label={t("ok")}
                            onClick={handleSubmit}
                            type="contained"
                        />,
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default UpdateSettings;
