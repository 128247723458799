import { Typography } from "@mui/material";
import React from "react";

type CustomTextProps = {
  label: string;
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2";
  bold?: boolean;
  italic?: boolean;
  color?: string;
};

const CustomText: React.FC<CustomTextProps> = ({
  label,
  type,
  bold,
  italic,
  color,
}) => {
  return (
    <Typography
      variant={type}
      style={{
        fontWeight: bold ? "bold" : "normal",
        fontStyle: italic ? "italic" : "normal",
        color: color ? color : undefined,
      }}
    >
      {label}
    </Typography>
  );
};

export default CustomText;
