import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { cranesListUrl, infoManagementUrl, mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { createCrane } from "../../../api/services/craneService";
import { useSnackbar } from "notistack";
import { AddCraneRequest } from "../../../api/requests/craneService";
import { ChromePicker } from 'react-color'

type CreateCraneProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const CreateCrane: React.FC<CreateCraneProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    code: "",
    description: "",
    notes: "",
    color : ""
  });

  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState('#fff')

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color: any) => {
    setColor(color.hex)
  }

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const handleSubmit = () => {
    createCrane({
      code: values.code,
      description: values.description,
      notes: values.notes,
      color: color

    } as AddCraneRequest).then((res) => {
      if (res && res.crane) {
        setValues({
          code: "",
          description: "",
          notes: "",
          color: ""
        });
        enqueueSnackbar(t("craneCreatedSuccess"), { variant: "success" });
        navigate(cranesListUrl);
      } else {
        enqueueSnackbar(`${t("craneCreatedError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(infoManagementUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("notes")}
                  value={values.notes}
                  handleChange={(e) =>
                    setValues({ ...values, notes: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <button onClick={ handleClick }>{t("pickColor")}</button>
                { displayColorPicker ? <div style={ popover }>
                  <div style={ cover } onClick={ handleClose }/>
                  <ChromePicker 
                    color={ color }
                    onChange={ handleChange }
                  />
                </div> : null }
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  code: "",
                  description: "",
                  notes: "",
                  color: ""
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateCrane;
