import { methodGet, verticalFetch } from "./httpRequests";
import { getDashboardControllerUrl, getDashboardManagerUrl } from "./url";

export const getDashboardController = (
  batch_id: string,
  shift_id: string,
  crane_id: string,
  date_shift: string
) => {
  return verticalFetch(
    methodGet,
    getDashboardControllerUrl(batch_id, shift_id, crane_id, date_shift)
  );
};

export const getDashboardManager = (
  batch_id: string,
  batch_status: string,
  shift_id: string,
  crane_id: string,
  start: string,
  end: string
) => {
  return verticalFetch(
    methodGet,
    getDashboardManagerUrl(batch_status, batch_id, shift_id, crane_id, start, end)
  );
};

export const getDashboardControllerResult = () => {
  return new Promise((resolve, reject) => {
    resolve({
      dashboard: {
        crane_id: "---",
        voyage: "---",
        bollard: "---",
        berthage: "---",
        crane_driver: "---",
        foreman: "---",
        controller: "---",
        crane_moves_hours: {
          date_shift: "---",
          value: 20,
          range: 80, //per dire se è in range o no mi serve la soglia (o le soglie)
        },
        crane_moves_summary: {
          date_shift: "---",
          charge: {
            20: {
              full: 3,
              empty: 7,
            },
            40: {
              full: 3,
              empty: 7,
            },
            XX: {
              full: 3,
              empty: 7,
            },
          },
          discharge: {
            20: {
              full: 3,
              empty: 7,
            },
            40: {
              full: 3,
              empty: 7,
            },
            XX: {
              full: 3,
              empty: 7,
            },
          },

        },
        vessel_projections: {
          total_moves: 300,
          moves_done: 200,
          moves_to_do: 100,
        },
        crane_projections: {
          mvs: 38,
          fl: "18-01-2022",
          ll: "20-01-2022",
          work: 123456, //numero in secondi che poi trasformerò in min, ore, ...
        },
      },
    });
  });
};
