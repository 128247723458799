import { Route, Routes, useNavigate } from "react-router-dom";
import {
  mainUrl,
  dashboardManagerUrl,
  roles,
  routes,
  shiftsUrl,
  updateUserUrl,
  usersListUrl,
  cranesListUrl,
  craneOperatorsListUrl,
  signalersListUrl,
  dockNumListUrl,
  dockModeListUrl,
  ordersListUrl,
  assignedResourcesUrl,
  resetPasswordUrl,
} from "./costants";

import Profile from "./profile/Profile";
import { getProfile } from "../../api/services/userService";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HomePage from "./homePages/HomePage";
import Navbar from "../navbar/Navbar";
import UpdateUser from "./updateUser/UpdateUser";
import Header from "../header/Header";
import CustomLoading from "../custom/CustomLoading";
import CreateUser from "./createUser/CreateUser";
import UsersList from "./usersList/UsersList";
import InfoManagement from "./infoManagement/InfoManagement";
import CranesList from "./cranes/CranesList";
import UpdateCrane from "./updateCrane/UpdateCrane";
import UpdateCraneOperator from "./updateCraneOperator/UpdateCraneOperator";
import UpdateDockNum from "./updateDockNum/UpdateDockNum";
import UpdateSignaler from "./updateSignaler/UpdateSignaler";
import CraneOperatorsList from "./craneOperators/CraneOperatorsList";
import SignalerList from "./signalers/SignalerList";
import DockModeList from "./dockMode/DockModeList";
import DockNumberList from "./numDock/NumDockList";
import DashboardControllerOverview from "./dashboardControllerOverview/DashboardControllerOverview";
import DashboardManagerOverview from "./dashboardManagerOverview/DashboardManagerOverview";
import Shifts from "./shifts/Shifts";
import { t } from "i18next";
import { useEffect, useState } from "react";
import CreateCrane from "./createCrane/CreateCrane";
import CreateDockNum from "./createDockNum/CreateDockNum";
import CreateDockMode from "./createDockMode/CreateDockMode";
import CreateSignaler from "./createSignaler/CreateSignaler";
import CreateCraneOperator from "./createCraneOperator/CreateCraneOperator";
import UpdateDockMode from "./updateDockMode/UpdateDockMode";
import CreateOrder from "./createOrder/CreateOrder";
import OrdersList from "./ordersList/OrdersList";
import UpdateOrder from "./updateOrder/UpdateOrder";
import AssignedResources from "./assignedResources/AssignedResources";
import AssignResources from "./assignResources/AssignResources";
import Dashboard from "./dashboardControllerOverview/Dashboard";
import DashboardManager from "./dashboardManager/DashboardManager";
import Settings from "./settings/Settings";
import UpdateResource from "./updateResource/UpdateResource";
import UpdatePassword from "./updatePassword/UpdatePassword";
import DashboardManagerMobile from "./dashboardManager/DashboardManagerMobile";

const MainPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(false); //booleano per visualizzare o collassare la navbar nel caso di schermi piccoli
  const [headrerCollapsed, setHeaderCollapsed] = useState<boolean>(false); //booleano per visualizzare o collassare l'header nel caso di schermi piccoli
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]); //array di oggetti da visualizzare nel breadcrumb
  const [loading, setLoading] = useState<boolean>(true); //booleano per capire quando finisce la verifica del login e quindi caricare la pagina
  const [role, setRole] = useState<string>(""); //stringa che indica il ruolo dell'utente loggato
  const [email, setEmail] = useState<string>(""); //stringa che indica la mail dell'utente loggato
  const [uid, setUid] = useState<string>(""); //stringa che indica l'uid dell'utente loggato
  const [configuratorRunning, setConfiguratorRunning] =
    useState<boolean>(false); //booleano per capire se il configuratore è in esecuzione

  //verifica che l'utente sia loggato e in base al ruolo gli assegna le giuste pagine da visualizzare
  useEffect(() => {
    getProfile().then((res: any) => {
      if (!res || !res.user) {
        navigate("/login");
      } else {
        setUid(res?.user?.uid);
        if (res.user.roles && res.user.roles.length > 0) {
          setRole(res.user.roles[0]);
          setEmail(res.user.email.split("@")[0]);
          const isLogin = (localStorage.getItem("isSCTLogin") || "") === "true";
          if (res.user.roles[0].includes(roles.admin) && isLogin) {
            localStorage.removeItem("isSCTLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.controller) && isLogin) {
            localStorage.removeItem("isSCTLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.manager) && isLogin) {
            localStorage.removeItem("isSCTLogin");
            navigate(dashboardManagerUrl);
          }
        }
      }
      setLoading(false);
    });
  }, []);

  const HomeView = () => {
    return <HomePage role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const InfoManagementView = () => {
    return <InfoManagement setBreadcrumb={setBreadcrumb} />;
  };

  const ProfileView = () => {
    return <Profile setBreadcrumb={setBreadcrumb} />;
  };

  const UsersListView = () => {
    return (
      <UsersList
        setBreadcrumb={setBreadcrumb}
        link={updateUserUrl}
        title="updateUser"
        types={["manager", "controller"]}
      />
    );
  };

  const ResetPasswordAdminView = () => {
    return (
      <UpdatePassword
        setBreadcrumb={setBreadcrumb}
        title="resetPassword"
        link={usersListUrl}
      />
    );
  };

  const CranesListView = () => {
    return (
      <CranesList
        setBreadcrumb={setBreadcrumb}
        title="cranes"
        link={cranesListUrl}
      />
    );
  };

  const OrdersListView = () => {
    return (
      <OrdersList
        setBreadcrumb={setBreadcrumb}
        title="orders"
        link={assignedResourcesUrl}
      />
    );
  };

  const AssignedResourcesView = () => {
    return (
      <AssignedResources
        setBreadcrumb={setBreadcrumb}
        title="assignedGang"
        link={assignedResourcesUrl}
      />
    );
  };

  const AssignResourcesView = () => {
    return (
      <AssignResources setBreadcrumb={setBreadcrumb} title="assignResources" />
    );
  };

  const CraneOperatorsListView = () => {
    return (
      <CraneOperatorsList
        setBreadcrumb={setBreadcrumb}
        title="craneOperators"
        link={craneOperatorsListUrl}
      />
    );
  };

  const SignalersListView = () => {
    return (
      <SignalerList
        setBreadcrumb={setBreadcrumb}
        title="signalers"
        link={signalersListUrl}
      />
    );
  };

  const DockModesView = () => {
    return <DockModeList setBreadcrumb={setBreadcrumb} title="dockMode" />;
  };

  const DockNumView = () => {
    return <DockNumberList setBreadcrumb={setBreadcrumb} title="numDocks" />;
  };

  const DashboardControllerView = () => {
    return (
      <DashboardControllerOverview
        setBreadcrumb={setBreadcrumb}
        title="dashboardOverview"
      />
    );
  };

  const DashboardManagerView = () => {
    return (
      <DashboardManagerOverview
        setBreadcrumb={setBreadcrumb}
        title="dashboardOverview"
      />
    );
  };

  const DashboardControllerResultView = () => {
    return (
      <Dashboard
        setBreadcrumb={setBreadcrumb}
        title="dashboardController"
        setNavbarCollapsed={setNavbarCollapsed}
        setHeaderCollapsed={setHeaderCollapsed}
        role={role}
        email={email}
      />
    );
  };

  const DashboardManagerDetailsView = () => {
    if (isMobile) {
      return (
        <DashboardManagerMobile
          setHeaderCollapsed={setHeaderCollapsed}
          setNavbarCollapsed={setNavbarCollapsed}
        />
      );
    } else {
      return (
        <DashboardManager
          setBreadcrumb={setBreadcrumb}
          title="dashboardManager"
          setHeaderCollapsed={setHeaderCollapsed}
          setNavbarCollapsed={setNavbarCollapsed}
          role={role}
          email={email}
        />
      );
    }
  };

  const shiftsView = () => {
    return (
      <Shifts setBreadcrumb={setBreadcrumb} link={shiftsUrl} title="shifts" />
    );
  };

  const UpdateUserView = () => {
    return (
      <UpdateUser
        link={usersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateUser"
      />
    );
  };

  const UpdateCraneView = () => {
    return (
      <UpdateCrane
        link={cranesListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateCrane"
      />
    );
  };

  const UpdateCraneOperatorView = () => {
    return (
      <UpdateCraneOperator
        link={craneOperatorsListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateCraneOperator"
      />
    );
  };

  const UpdateDockNumView = () => {
    return (
      <UpdateDockNum
        link={dockNumListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateDockNum"
      />
    );
  };

  const UpdateDockModeView = () => {
    return (
      <UpdateDockMode
        link={dockModeListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateDockMode"
      />
    );
  };

  const UpdateSignalerView = () => {
    return (
      <UpdateSignaler
        link={signalersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateSignaler"
      />
    );
  };

  const UpdateOrderView = () => {
    return (
      <UpdateOrder
        link={ordersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateOrder"
      />
    );
  };

  const UpdateResourceView = () => {
    return (
      <UpdateResource
        link={ordersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateResource"
      />
    );
  };

  const SettingsView = () => {
    return (
      <Settings setBreadcrumb={setBreadcrumb} title="settings" link={""} />
    );
  };

  const CreateUsersView = () => {
    return (
      <CreateUser
        roles={[
          {
            label: `${t("controller")}`,
            value: roles.controller,
          },
          {
            label: `${t("manager")}`,
            value: roles.manager,
          },
        ]}
        setBreadcrumb={setBreadcrumb}
        title="createUser"
      />
    );
  };

  const CreateOrderView = () => {
    return <CreateOrder setBreadcrumb={setBreadcrumb} title="createOrder" />;
  };

  const CreateCraneView = () => {
    return <CreateCrane setBreadcrumb={setBreadcrumb} title="createCrane" />;
  };

  const CreateCraneOperatorView = () => {
    return (
      <CreateCraneOperator
        setBreadcrumb={setBreadcrumb}
        title="createCraneOperator"
      />
    );
  };

  const CreateDockModeView = () => {
    return (
      <CreateDockMode setBreadcrumb={setBreadcrumb} title="createDockMode" />
    );
  };

  const CreateDockNumView = () => {
    return (
      <CreateDockNum setBreadcrumb={setBreadcrumb} title="createNumDock" />
    );
  };

  const CreateSignalerView = () => {
    return (
      <CreateSignaler setBreadcrumb={setBreadcrumb} title="createSignaler" />
    );
  };

  const CreateAdminView = () => {
    return (
      <CreateUser
        roles={[{ label: `${t("admin")}`, value: roles.admin }]}
        setBreadcrumb={setBreadcrumb}
        title="createAdmin"
      />
    );
  };

  const AdminListView = () => {
    return (
      <UsersList
        setBreadcrumb={setBreadcrumb}
        link={updateUserUrl}
        title="updateAdminCompany"
        types={["admin"]}
      />
    );
  };

  return (
    <>
      <Header
        configuratorRunning={configuratorRunning}
        breadcrumbs={breadcrumb}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
        visibility={headrerCollapsed ? "hidden" : "visible"}
      />
      <Navbar
        configuratorRunning={configuratorRunning}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
      />
      {loading ? (
        <CustomLoading />
      ) : (
        <div style={{ marginLeft: navbarCollapsed ? "0px" : "230px" }}>
          <Routes>
            <Route
              path={routes.infoManagement}
              element={InfoManagementView()}
            />
            <Route path={routes.profile} element={ProfileView()} />
            <Route path={routes.createUsers} element={CreateUsersView()} />
            <Route path={routes.usersList} element={UsersListView()} />
            <Route
              path={routes.resetPassword}
              element={ResetPasswordAdminView()}
            />

            <Route path={routes.createCrane} element={CreateCraneView()} />
            <Route path={routes.cranesList} element={CranesListView()} />
            <Route path={routes.updateCrane} element={UpdateCraneView()} />

            <Route
              path={routes.createCraneOperator}
              element={CreateCraneOperatorView()}
            />
            <Route
              path={routes.craneOperatorsList}
              element={CraneOperatorsListView()}
            />
            <Route
              path={routes.updateCraneOperator}
              element={UpdateCraneOperatorView()}
            />

            <Route
              path={routes.createDockMode}
              element={CreateDockModeView()}
            />
            <Route path={routes.dockModeList} element={DockModesView()} />
            <Route
              path={routes.updateDockMode}
              element={UpdateDockModeView()}
            />

            <Route
              path={routes.createSignaler}
              element={CreateSignalerView()}
            />
            <Route path={routes.signalersList} element={SignalersListView()} />
            <Route
              path={routes.updateSignaler}
              element={UpdateSignalerView()}
            />

            <Route path={routes.createDockNum} element={CreateDockNumView()} />
            <Route path={routes.dockNumList} element={DockNumView()} />
            <Route path={routes.updateDockNum} element={UpdateDockNumView()} />

            <Route
              path={routes.getDashboardManagerResult}
              element={DashboardManagerDetailsView()}
            />
            <Route
              path={routes.dashboardManager}
              element={DashboardManagerView()}
            />
            <Route
              path={routes.getDashboardControllerResult}
              element={DashboardControllerResultView()}
            />
            <Route
              path={routes.dashboardController}
              element={DashboardControllerView()}
            />

            <Route path={routes.shifts} element={shiftsView()} />
            <Route path={routes.updateUser} element={UpdateUserView()} />
            <Route path={routes.createAdmin} element={CreateAdminView()} />
            <Route path={routes.adminList} element={AdminListView()} />

            <Route path={routes.createOrders} element={CreateOrderView()} />
            <Route path={routes.ordersList} element={OrdersListView()} />
            <Route path={routes.updateOrder} element={UpdateOrderView()} />
            <Route
              path={routes.assignedResources}
              element={AssignedResourcesView()}
            />
            <Route
              path={routes.getResourcesToAssign}
              element={AssignResourcesView()}
            />
            <Route
              path={routes.assignResources}
              element={AssignResourcesView()}
            />
            <Route
              path={routes.updateResource}
              element={UpdateResourceView()}
            />

            <Route path={routes.settings} element={SettingsView()} />

            <Route path={routes.mainPage} element={HomeView()} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default MainPage;
