import React from "react";
import logo from "../../../images/logo_verticale.jpeg";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import { CheckCircle } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import { darkColor } from "../../custom/utils";

type ResultResetPasswordProps = {};

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = darkColor;

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid
                item
                container
                spacing={2}
                alignItems="center"
                flexDirection={"column"}
              >
                <Grid item xs={12}>
                  <img
                    src={logo}
                    alt="logo"
                    width="70%"
                    style={{ marginLeft: "20%", marginRight: "20%" }}
                  />
                </Grid>
                <Grid item xs={12} alignItems={"center"}>
                  <CheckCircle style={{ color: "green", fontSize: "150px" }} />
                </Grid>
                <Grid item xs={12}>
                  <CustomText label={t("resetPasswordResultTitle")} type="h4" />
                </Grid>
                <Grid item xs={12}>
                  <CustomText
                    label={t("resetPasswordResultSubTitle")}
                    type="subtitle1"
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultResetPassword;
