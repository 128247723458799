import { AddSignalerRequest, DeleteSignalerRequest, UpdateSignalerRequest } from "../requests/signalerService";
import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

import {
  createSignalerUrl,
  getProfileUrl,
  getSignalerUrl,
  getSignalersUrl,
  updateSignalerUrl,
  deleteSignalerUrl,
} from "./url";

export const createSignaler = (body: AddSignalerRequest) => {
  return verticalFetch(methodPost, createSignalerUrl, JSON.stringify(body));
};

export const getProfile = () => {
  return verticalFetch(methodGet, getProfileUrl);
};

export const getSignaler = (uid: string) => {
  return verticalFetch(methodGet, getSignalerUrl(uid));
};

export const getSignalers = () => {
  return verticalFetch(methodGet, getSignalersUrl);
};

export const updateSignaler = (uid: string, body: UpdateSignalerRequest) => {
  return verticalFetch(methodPut, updateSignalerUrl(uid), JSON.stringify(body));
};

export const deleteSignaler = (uid: string, body: DeleteSignalerRequest) => {
  return verticalFetch(methodPut, deleteSignalerUrl(uid), JSON.stringify(body));
};
