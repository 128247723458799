import React from "react";
import { ListElement } from "./types";
import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

type CustomListProps = {
  listElement: ListElement[];
  dense?: boolean;
};

const CustomList: React.FC<CustomListProps> = ({ listElement, dense }) => {
  return (
    <List dense={dense ? dense : false}>
      {listElement.map((element, index) => (
        <ListItem
          alignItems="center"
          onClick={element.onClick ? element.onClick : undefined}
          secondaryAction={element.action ? element.action : undefined}
        >
          {element.startIcon && (
            <ListItemAvatar>{element.startIcon}</ListItemAvatar>
          )}
          <ListItemText
            primary={element.primaryText}
            secondary={
              element.secondaryText ? element.secondaryText : undefined
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CustomList;
