import {
  ArrowBack,
  CheckCircleOutline,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../images/logo_verticale.jpeg";
import CustomCard from "../../custom/CustomCard";
import CustomLoading from "../../custom/CustomLoading";
import CustomText from "../../custom/CustomText";
import {
  assignedResourcesUrl,
  dashboardControllerUrl,
  mainUrl,
  routes,
  updateOrderUrl,
} from "../costants";
import { getDashboardController } from "../../../api/services/dashboardService";
import { darkColor, lightColor } from "../../custom/utils";
import CustomTag from "../../custom/CustomTag";

type DashboardProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  email: string;
};

const Dashboard: React.FC<DashboardProps> = ({
  setBreadcrumb,
  title,
  setNavbarCollapsed,
  setHeaderCollapsed,
  role,
  email,
}) => {
  document.body.style.overflow = "hidden";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const date = searchParams.get("date");
  const home = searchParams.get("home");

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
    return () => {
      setNavbarCollapsed(false);
      setHeaderCollapsed(false);
    };
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    const updateData = () =>
      getDashboardController(batch_id!, shift_id!, crane_id!, date!).then(
        (res) => {
          console.log("res", res);
          if (res && res.dashboard) {
            setData({ ...res.dashboard });
          } else {
            enqueueSnackbar(t("noResources"), { variant: "error" });
            navigate(dashboardControllerUrl);
          }
          setLoading(false);
        }
      );
    updateData();
    setInterval(() => {
      updateData();
    }, 30000);
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid
        item
        container
        xs={2}
        flexDirection="column"
        style={{ backgroundColor: darkColor, color: "white", height: "110vh" }}
      >
        <Grid item>
          <Typography variant="h1">{data.crane_id}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {data.crane_moves_hours.date_shift}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={10}
        container
        style={{ paddingRight: "10px" }}
        alignItems="flex-start"
      >
        <Grid item container style={{ height: "80px" }} alignItems="center">
          <Grid item xs>
            <IconButton
              size="small"
              onClick={
                home
                  ? () => navigate(assignedResourcesUrl(batch_id!))
                  : () => navigate(dashboardControllerUrl)
              }
              style={{ backgroundColor: lightColor }}
            >
              <ArrowBack
                fontSize="small"
                htmlColor={darkColor}
                style={{ color: "white" }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "center" }}>
            <Typography variant="h3" fontWeight={600}>
              <Link
                to={updateOrderUrl(data.order_id!)}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {data.description}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid
            item
            container
            xs={2}
            alignItems="center"
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <CustomTag label={role} type="outlined" />
              <CustomText color={darkColor} label={email} type="body1" />
            </Grid>
            <Grid item>
              <img
                style={{ cursor: "pointer", height: "80px" }}
                alt="logo"
                src={logo}
                onClick={() => navigate(routes.mainPage)}
              />{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={4}
          flexDirection="column"
          style={{ marginTop: "-60px" }}
        >
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("gmph")}
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "-20px" }}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                variant="h1"
                color="#7b1b02"
                style={{ fontSize: "150px" }}
              >
                {data.crane_moves_hours.value}
              </Typography>
              {data.crane_moves_hours.value >= data.crane_moves_hours.range ? (
                <Chip
                  icon={<CheckCircleOutline />}
                  label={t("rangeSuccess")}
                  color="success"
                  variant="filled"
                />
              ) : (
                <Chip
                  icon={<ErrorOutlineOutlined />}
                  label={t("rangeError")}
                  color="error"
                  variant="filled"
                />
              )}
            </Box>
          </Grid>
          <Grid item>
            <Typography
              color="#7b1b02"
              variant="h6"
              fontWeight={600}
              style={{ marginTop: "-20px" }}
            >
              {t("moves")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              color="#7b1b02"
              style={{ fontSize: "150px", marginTop: "-20px" }}
            >
              {data.crane_projections.mvs}
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "-20px" }}>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("firstLift")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.crane_projections.fl !== "0001-01-01T00:00:00Z"
                ? data.crane_projections.fl.split("T")[0] +
                  " " +
                  data.crane_projections.fl.split("T")[1].split("Z")[0]
                : "N.A."}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("lastLift")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.crane_projections.ll !== "0001-01-01T00:00:00Z"
                ? data.crane_projections.ll.split("T")[0] +
                  " " +
                  data.crane_projections.ll.split("T")[1].split("Z")[0]
                : "N.A."}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("gwt")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.crane_projections.work}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("shiftTimeToGo")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.crane_projections.remaining_time}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("driver")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.crane_driver}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6" fontWeight={600}>
              {t("foreman")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="#7b1b02" variant="h6">
              {data.foreman}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={8} style={{ marginTop: "-60px" }}>
          <Grid item container flexDirection="column" xs={6}>
            <Grid item>
              <Typography color="#7b1b02" variant="h6" fontWeight={600}>
                {t("totalMoves")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6">
                {data.vessel_projections.total_moves}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6" fontWeight={600}>
                {t("toDo")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6">
                {data.vessel_projections.moves_to_do}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6" fontWeight={600}>
                {t("done")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6">
                {data.vessel_projections.moves_done}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container flexDirection="column" xs={6}>
            <Grid item>
              <Typography color="#7b1b02" variant="h6" fontWeight={600}>
                {t("bollard")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6">
                {data.bollard_description}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#7b1b02" variant="h6" fontWeight={600}>
                {t("berthage")}
                <Typography color="#7b1b02" variant="h6">
                  {data.berthage_description}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomCard
              header={{
                visible: true,
                title: `${t("craneMovesSummary")} - ${t("discharge")}`,
                sx: { py: 0 },
              }}
              content={
                <Grid container>
                  <Grid item xs={12}>
                    <CustomText
                      type="subtitle2"
                      label={data.crane_moves_hours.date_shift}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Chart
                      chartType="ColumnChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          t("category"),
                          t("full"),
                          t("empty"),
                          { role: "annotation" },
                        ],
                        [
                          t("20"),
                          data.crane_moves_summary.discharge.twenty.full,
                          data.crane_moves_summary.discharge.twenty.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.discharge.twenty.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.discharge.twenty.empty
                          }`,
                        ],
                        [
                          t("40"),
                          data.crane_moves_summary.discharge.forty.full,
                          data.crane_moves_summary.discharge.forty.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.discharge.forty.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.discharge.forty.empty
                          }`,
                        ],
                        [
                          t("XX"),
                          data.crane_moves_summary.discharge.xx.full,
                          data.crane_moves_summary.discharge.xx.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.discharge.xx.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.discharge.xx.empty
                          }`,
                        ],
                      ]}
                      options={{
                        chartArea: { height: "53%" },
                        hAxis: {
                          minValue: 0,
                        },
                        isStacked: true,
                        annotations: {
                          alwaysOutside: true, // Le etichette rimangono sempre all'esterno delle colonne
                          highContrast: true,
                          textStyle: {
                            fontSize: 12,
                            auraColor: "none", // Rimuove il contorno bianco attorno al testo
                            color: "#000", // Colore del testo (modificalo se necessario)
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <CustomCard
              header={{
                visible: true,
                title: `${t("craneMovesSummary")} - ${t("load")}`,
                sx: { py: 0 },
              }}
              content={
                <Grid container>
                  <Grid item xs={12}>
                    <CustomText
                      type="subtitle2"
                      label={data.crane_moves_hours.date_shift}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Chart
                      chartType="ColumnChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          t("category"),
                          t("full"),
                          t("empty"),
                          { role: "annotation" },
                        ],
                        [
                          t("20"),
                          data.crane_moves_summary.charge.twenty.full,
                          data.crane_moves_summary.charge.twenty.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.charge.twenty.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.charge.twenty.empty
                          }`,
                        ],
                        [
                          t("40"),
                          data.crane_moves_summary.charge.forty.full,
                          data.crane_moves_summary.charge.forty.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.charge.forty.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.charge.forty.empty
                          }`,
                        ],
                        [
                          t("XX"),
                          data.crane_moves_summary.charge.xx.full,
                          data.crane_moves_summary.charge.xx.empty,
                          `${t("full")}: ${
                            data.crane_moves_summary.charge.xx.full
                          } ${t("empty")}: ${
                            data.crane_moves_summary.charge.xx.empty
                          }`,
                        ],
                      ]}
                      options={{
                        chartArea: { height: "53%" },
                        hAxis: {
                          minValue: 0,
                        },
                        isStacked: true,
                        annotations: {
                          alwaysOutside: true, // Le etichette rimangono sempre all'esterno delle colonne
                          highContrast: true,
                          textStyle: {
                            fontSize: 12,
                            auraColor: "none", // Rimuove il contorno bianco attorno al testo
                            color: "#000", // Colore del testo (modificalo se necessario)
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
