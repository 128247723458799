import { json } from "react-router-dom";
import { AddCraneOperatorRequest, DeleteCraneOperatorRequest, UpdateCraneOperatorRequest } from "../requests/craneOperatorService";
import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

import {
  createCraneOperatorUrl,
  getProfileUrl,
  getCraneOperatorUrl,
  getCraneOperatorsUrl,
  updateCraneOperatorUrl,
  deleteCraneOperatorUrl
} from "./url";

export const createCraneOperator = (body: AddCraneOperatorRequest) => {
  return verticalFetch(methodPost, createCraneOperatorUrl, JSON.stringify(body));
};

export const getProfile = () => {
  return verticalFetch(methodGet, getProfileUrl);
};

export const getCraneOperator = (uid: string) => {
  return verticalFetch(methodGet, getCraneOperatorUrl(uid));
};

export const getCraneOperators = () => {
  return verticalFetch(methodGet, getCraneOperatorsUrl);
};

export const updateCraneOperator = (uid: string, body: UpdateCraneOperatorRequest) => {
  return verticalFetch(methodPut, updateCraneOperatorUrl(uid), JSON.stringify(body));
};

export const deleteCraneOperator = (uid: string, body: DeleteCraneOperatorRequest) => {
  return verticalFetch(methodPut, deleteCraneOperatorUrl(uid), JSON.stringify(body));
};
