import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { assignedResourcesUrl, cranesListUrl, infoManagementUrl, mainUrl, ordersListUrl } from "../costants";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { getAvailableResources, getResource, getResourcesToAssign, updateResource } from "../../../api/services/resourceService";
import CustomSelect from "../../custom/CustomSelect";
import { getProfile } from "../../../api/services/userService";

type UpdateResourceProps = {
    setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
    title: string;
    link: string;
};

const UpdateResource: React.FC<UpdateResourceProps> = ({
    setBreadcrumb,
    title,
    link,
}) => {
    const { id } = useParams(); //id
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [cranes, setCranes] = useState<any[]>([]);
    const [craneOperators, setCraneOperators] = useState<any[]>([]);
    const [signalers, setSignalers] = useState<any[]>([]);
    const [orders, setOrders] = useState<any[]>([]);
    const [values, setValues] = useState({
        order_id: "",
        crane: "",
        crane_operator: "",
        shift: "",
        signaler: "",
        controller: "",
        date_shift: "",
    });

    useEffect(() => {
        setBreadcrumb([
            <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
                {t("home")}
            </Link>,
            <Link style={{ color: "#ffffff" }} key="2" to={link}>
                {t(title)}
            </Link>,
        ]);
    }, []);

    useEffect(() => {
        getResource(id!).then((res: any) => {
            if (res && res.resource) {
                const tmp = {
                    order_id: res.resource?.order_id,
                    crane: String(res.resource?.crane_id),
                    crane_operator: String(res.resource?.crane_operator_id),
                    shift: res.resource?.shift,
                    signaler: String(res.resource?.signaler_id),
                    controller: res.resource?.controller,
                    date_shift: (res.resource?.assigned_at).split("T")[0],
                }

                getAvailableResources((res.resource?.assigned_at).split("T")[0], res.resource?.shift).then((res1: any) => {
                    if (res1 && res1.resources) {
                        if (res1.resources.cranes == null && res1.resources.crane_operators == null && res1.resources.signalers == null) {
                            enqueueSnackbar(t("noResourcesAvailable"), { variant: "error" });
                        } else {

                            if (res1.resources.orders && res1.resources.orders.length > 0) {
                                setOrders([...res1.resources.orders]);
                            }

                            if (res1.resources.cranes && res1.resources.cranes.length > 0) {
                                setCranes([...res1.resources.cranes.map((crane: any) => ({ value: String(crane.code), label: crane.description })), { value: String(res.resource?.crane_id), label: res.resource?.crane }]);
                            } else {
                                setCranes([{ value: String(res.resource?.crane_id), label: res.resource?.crane }]);
                            }

                            if (res1.resources.crane_operators && res1.resources.crane_operators.length > 0) {
                                setCraneOperators([...res1.resources.crane_operators.map((craneOperator: any) => ({ value: String(craneOperator.code), label: craneOperator.description })), { value: String(res.resource?.crane_operator_id), label: res.resource?.crane_operator }]);
                            } else {
                                setCraneOperators([{ value: String(res.resource?.crane_operator_id), label: res.resource?.crane_operator }]);
                            }

                            if (res1.resources.signalers && res1.resources.signalers.length > 0) {
                                setSignalers([...res1.resources.signalers.map((signaler: any) => ({ value: String(signaler.code), label: signaler.description })), { value: String(res.resource?.signaler_id), label: res.resource?.signaler }]);
                            } else {
                                setSignalers([{ value: String(res.resource?.signaler_id), label: res.resource?.signaler }]);
                            }
                        }
                    } else {
                        enqueueSnackbar(t("errorGettingResources"), { variant: "error" });
                    }

                    getProfile().then((res2) => {
                        if (res2 && res2.user) {
                            console.log("TMP: ", { ...tmp, controller: res2.user.email })
                            setValues({ ...tmp, controller: res2.user.email });
                        }
                        setLoading3(false);
                    });
                    setLoading2(false);
                })
            }
        });
        setLoading(false);
    }, []);

    const handleSubmit = () => {
        updateResource(id!, {
            order_id: id!,
            crane_id: Number(values.crane),
            crane_operator: Number(values.crane_operator),
            shift: values.shift,
            signaler: Number(values.signaler),
            controller: values.controller,
            date_shift: values.date_shift,
        }).then((res) => {
            if (res && res.err) {
                enqueueSnackbar(t("updateResourceError"), { variant: "error" });
            } else {
                enqueueSnackbar(t("updateResourceSuccess"), { variant: "success" });
                navigate(-1);
            }
        });
    };

    if (loading || loading2 || loading3) {
        return <CustomLoading />;
    }

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ padding: "100px 32px" }}
        >
            <Grid item xs={12}>
                <CustomTitle title={t("updateResource") + " - " + t("order") + " " + values.order_id} goBack={() => navigate(-1)} />
            </Grid>
            <Grid item xs={12} md={8}>
                <CustomCard
                    content={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomSelect
                                    label={`${t("crane")}`}
                                    value={String(values.crane)}
                                    handleChange={(e) =>
                                        setValues({ ...values, crane: e.target.value })
                                    }
                                    options={cranes}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomSelect
                                    label={`${t("craneOperator")}`}
                                    value={values.crane_operator}
                                    handleChange={(e) =>
                                        setValues({ ...values, crane_operator: e.target.value })
                                    }
                                    options={craneOperators}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomSelect
                                    label={`${t("signaler")}`}
                                    value={values.signaler}
                                    handleChange={(e) =>
                                        setValues({ ...values, signaler: e.target.value })
                                    }
                                    options={signalers}
                                />
                            </Grid>
                        </Grid>
                    }
                    actions={[
                        <CustomButton
                            fullWidth
                            label={t("ok")}
                            onClick={handleSubmit}
                            type="contained"
                        />,
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default UpdateResource;
