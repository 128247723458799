import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { infoManagementUrl, routes, updateSignalerUrl } from "../costants";
import { deleteSignaler, getSignalers } from "../../../api/services/signalerService";
import CustomLoading from "../../custom/CustomLoading";
import CustomIconButton from "../../custom/CustomIconButton";
import { DeleteForeverOutlined, ModeEdit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DeleteSignalerRequest } from "../../../api/requests/signalerService";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CustomText from "../../custom/CustomText";
import Tooltip from '@mui/material/Tooltip';

type SignalerListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
};

const SignalerList: React.FC<SignalerListProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={infoManagementUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [signalers, setSignalers] = useState<any[]>([]);

  useEffect(() => {
    getSignalers().then((res: any) => {
      if (res && res.signalers) {
        setSignalers([...res.signalers]);
      } else {
        setSignalers([]);
      }
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(infoManagementUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("code")}`,
              field: "code",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.code}</>
              ),
            },
            {
              headerName: `${t("description")}`,
              field: "description",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.description}
                </>
              ),
            },
            {
              headerName: `${t("notes")}`,
              field: "notes",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.notes}
                </>
              ),
            },
            {
              headerName: "",
              field: "",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> 
                  <CustomIconButton type="outlined" icon={<Tooltip title={t("edit")} ><ModeEdit /></Tooltip>} onClick={() => navigate(updateSignalerUrl(params.row.code))} />
                  <CustomIconButton type="outlined" confirm icon={<Tooltip title={t("delete")} ><DeleteForeverOutlined /></Tooltip>} onClick={() => 
                    deleteSignaler(params.row.code, {code: (params.row.code).toString()} as DeleteSignalerRequest).then((res: any) => {
                      if (res && !res.error) {
                        enqueueSnackbar(t("signalerDeletedSuccess"), { variant: "success" });
                        setReload(!reload);
                      } else {
                        enqueueSnackbar(`${t("signalerDeletedError")} ${res?.err?.message}`, {
                          variant: "error",
                        });
                      }
                    }
                )} 
                />
                </>
              ),
            },  
          ]}
          rows={signalers}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <CustomIconButton type="outlined" icon={<AddCircleOutlinedIcon fontSize="large"/>} onClick={() => navigate(routes.createSignaler)} />
          <CustomText type='h6' label={t("createSignaler")} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignalerList;
