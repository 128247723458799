import {
  createOrdersUrl,
  mainUrl,
  cranesListUrl,
  craneOperatorsListUrl,
  signalersListUrl,
  createUsersUrl,
  usersListUrl,
  dashboardControllerUrl,
  dashboardManagerUrl,
  shiftsUrl,
  infoManagementUrl,
  dockNumListUrl,
  dockModeListUrl,
  ordersListUrl,
  assignResourcesUrl,
  settingsUrl,
} from "../pages/costants";

export const adminMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "userManagement",
    label: t("userManagement"),
    link: mainUrl,
    elements: [
      {
        key: "createUser",
        label: t("createUser"),
        elements: [],
        link: createUsersUrl,
      },
      {
        key: "updateUser",
        label: t("updateUser"),
        elements: [],
        link: usersListUrl,
      },
    ],
  },
  {
    key: "infoManagement",
    label: t("infoManagement"),
    link: infoManagementUrl,
    elements: [
      {
        key: "cranes",
        label: t("cranes"),
        elements: [],
        link: cranesListUrl,
      },
      {
        key: "numDocks",
        label: t("numDocks"),
        elements: [],
        link: dockNumListUrl,
      },
      {
        key: "dockMode",
        label: t("dockMode"),
        elements: [],
        link: dockModeListUrl,
      },
      {
        key: "craneOperators",
        label: t("craneOperators"),
        elements: [],
        link: craneOperatorsListUrl,
      },
      {
        key: "signalers",
        label: t("signalers"),
        elements: [],
        link: signalersListUrl,
      },
      {
        key: "shifts",
        label: t("infoShifts"),
        link: shiftsUrl,
        elements: [],
      },
    ],
  },
  {
    key: "settings",
    label: t("movesPerHour"),
    link: settingsUrl,
    elements: [],
  },
];

export const controllerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "updateOrder",
    label: t("ordersList"),
    link: ordersListUrl,
    elements: [],
  },
  {
    key: "assignResources",
    label: t("assignResources"),
    link: assignResourcesUrl,
    elements: [],
  },
  {
    key: "dashboard",
    label: t("dashboard"),
    link: dashboardControllerUrl,
    elements: [],
  },
];

export const managerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "dashboard",
    label: t("dashboard"),
    link: dashboardManagerUrl,
    elements: [],
  },
];
