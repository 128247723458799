import React from "react";
import { darkColor, lightColor } from "./utils";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

type CustomTitleProps = {
  title: string;
  subtitle?: string;
  goBack?: () => void;
  titleLink?: any;
  action?: React.ReactNode;
};

const CustomTitle: React.FC<CustomTitleProps> = ({
  title,
  subtitle,
  goBack,
  titleLink,
  action,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container alignItems="center" style={{ padding: "8px" }}>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={isMobile ? 2 : 0.5}>
          {goBack && (
            <IconButton
              size="small"
              onClick={goBack}
              style={{ backgroundColor: lightColor }}
            >
              <ArrowBack
                fontSize="small"
                htmlColor={darkColor}
                style={{ color: "white" }}
              />
            </IconButton>
          )}
        </Grid>
        <Grid xs={isMobile ? 10 : 10.5} item container flexDirection="column">
          <Grid item style={{ width: "fit-content" }}>
            {titleLink ? (
              <Link
                to={typeof titleLink === "string" ? titleLink : titleLink()}
                style={{ color: "inherit", width: "fit-content" }}
              >
                <Typography
                  variant={isMobile ? "subtitle1" : "h5"}
                  style={{ color: darkColor }}
                >
                  {title}
                </Typography>
              </Link>
            ) : (
              <Typography
                variant={isMobile ? "subtitle1" : "h5"}
                style={{ color: darkColor }}
              >
                {title}
              </Typography>
            )}
          </Grid>
          {subtitle && (
            <Grid item>
              <Typography variant="subtitle2" style={{ color: darkColor }}>
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
        {!isMobile && (
          <Grid item xs={isMobile ? 0 : 1}>
            {action}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ borderColor: lightColor, borderWidth: "1.2px" }} />
      </Grid>
    </Grid>
  );
};

export default CustomTitle;
