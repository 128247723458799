import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import {  getDashboardControllerUrl, mainUrl, ordersListUrl, routes, updateResourceUrl } from "../costants";
import {  deleteResource, getResources } from "../../../api/services/resourceService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CustomIconButton from "../../custom/CustomIconButton";
import CustomText from "../../custom/CustomText";
import { DeleteForeverOutlined, ModeEdit } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import BarChartIcon from '@mui/icons-material/BarChart';
import { DeleteResourceRequest } from "../../../api/requests/resourceService";

type AssignedResourcesProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
};

const AssignedResources: React.FC<AssignedResourcesProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //order uid
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [resources, setResources] = useState<any[]>([]);
  useEffect(() => {
    getResources(id!).then((res: any) => {
      if (res && res.resources) {
        setResources([...res.resources]);
      } else {
        setResources([]);
      }
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("order") + " " + id + ": " + t(title)} goBack={() => navigate(ordersListUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("assignment")}`,
              field: "assigned",
              description: `${t("assignment")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {(params.row.assigned_at).split("T")[0]}
                </>
              ),
            },
            {
              headerName: `${t("shift")}`,
              field: "shift",
              description: `${t("shift")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {t("shift") + " " + params.row.shift}
                </>
              ),
            },
            {
              headerName: `${t("crane")}`,
              field: "crane",
              description: `${t("crane")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.crane}
                </>
              ),
            },
            {
              headerName: `${t("craneOperator")}`,
              field: "craneOperator",
              description: `${t("craneOperator")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.crane_operator}
                </>
              ),
            },
            {
              headerName: `${t("signaler")}`,
              field: "signaler",
              description: `${t("signaler")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.signaler}
                </>
              ),
            },
            {
              headerName: `${t("controller")}`,
              field: "controller",
              description: `${t("controller")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.controller}
                </>
              ),
            },
            {
              headerName: `${t("created")}`,
              field: "created_at",
              description: `${t("created")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {(params.row.created_at).split("T")[0] + " " + (params.row.created_at).split("T")[1].split(".")[0]}
                </>
              ),
            },
            {
              headerName: "",
              field: "",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  <CustomIconButton type="outlined" icon={<Tooltip title={t("edit")} ><ModeEdit /></Tooltip>} onClick={() => navigate(updateResourceUrl(params.row.id))} />
                  <CustomIconButton type="outlined" icon={<Tooltip title={t("seeDashboard")} ><BarChartIcon /></Tooltip>} onClick={() => {
                    console.log("params.row", params.row);
                    navigate(getDashboardControllerUrl(id!, params.row.shift, params.row.crane, ((params.row.assigned_at).split("T")[0]), true));
                  }
                  } />
                  <CustomIconButton type="outlined" confirm icon={<Tooltip title={t("delete")} ><DeleteForeverOutlined /></Tooltip>} onClick={() =>
                    deleteResource(params.row.id, { order_id: (params.row.id).toString() } as DeleteResourceRequest).then((res: any) => {
                      if (res && !res.error) {
                        enqueueSnackbar(t("resourceDeletedSuccess"), { variant: "success" });
                        setReload(!reload);
                      } else {
                        enqueueSnackbar(`${t("resourceDeletedError")} ${res?.err?.message}`, {
                          variant: "error",
                        });
                      }
                    }
                    )}
                  />
                </>
              ),
            },
          ]}
          rows={resources}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <CustomIconButton type="outlined" icon={<AddCircleOutlinedIcon fontSize="large" />} onClick={() => navigate(routes.getResourcesToAssignWithId(id!))} />
          <CustomText type='h6' label={t("assignResources")} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignedResources;
