import React, { useEffect, useState } from "react";
import { getProfile } from "../../../api/services/userService";
import { isDev, mainUrl } from "../costants";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomList from "../../custom/CustomList";
import CustomTitle from "../../custom/CustomTitle";
import { Link, useNavigate } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import { greyColor } from "../../custom/utils";
import Test from "./Test";

type ProfileProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const Profile: React.FC<ProfileProps> = ({ setBreadcrumb }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("profile")}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfile({
          ...res.user,
        });
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("profile")} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={{ visible: true, title: `${t("account")}` }}
          content={
            <CustomList
              listElement={[
                {
                  primaryText: t("username"),
                  action: (profile?.email).split("@")[0] || "N.A.",
                },
                {
                  primaryText: t("name"),
                  action: profile?.name || "N.A.",
                },
                {
                  primaryText: t("role"),
                  action:
                    profile && profile.roles && profile.roles.length > 0 ? (
                      <CustomTag
                        label={profile.roles[0]}
                        color={greyColor}
                        type="filled"
                      />
                    ) : (
                      "N.A."
                    ),
                },
                {
                  primaryText: t("creationDate"),
                  action:
                    profile && profile.created_at
                      ? new Date(profile.created_at).toLocaleString()
                      : "N.A.",
                },
              ]}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={{ visible: true, title: `${t("addressAndContacts")}` }}
          content={
            <CustomList
              listElement={[
                {
                  primaryText: t("onlyAddress"),
                  action: profile?.user_info?.address || "N.A.",
                },
                {
                  primaryText: t("city"),
                  action: profile?.user_info?.city || "N.A.",
                },
                {
                  primaryText: t("province"),
                  action: profile?.user_info?.province || "N.A.",
                },
                {
                  primaryText: t("phone"),
                  action: profile?.user_info?.phone || "N.A.",
                },
              ]}
            />
          }
        />
      </Grid>
      {isDev && (
        <Grid>
          <Test />
        </Grid>
      )}
    </Grid>
  );
};

export default Profile;
