import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { mainUrl, usersListUrl } from "../costants";
import UsersList from "../usersList/UsersList";
import { getUser, resetPassword } from "../../../api/services/userService";
import { Password } from "@mui/icons-material";

type UpdatePasswordProps = {
    setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
    title: string;
    link: string;
};

const UpdatePassword: React.FC<UpdatePasswordProps> = ({
    setBreadcrumb,
    title,
    link,
}) => {
    const { id } = useParams(); //user uid
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<string>();
    const [newPassword, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    useEffect(() => {
        getUser(id!).then((res) => {
            if (res && res.user) {
                setUser(res.user.name);
                setPassword("");
                setConfirmPassword("");
            } else {
                enqueueSnackbar(t("GetUserError"), { variant: "error" });
            }
            setLoading(false);
        })
    }, []);

    if (loading) return <CustomLoading />;

    const handleSubmit = () => {
        console.log(newPassword);
        console.log(confirmPassword);
        resetPassword(id!, {
            user_id: id!,
            password: newPassword,
            confirm_password: confirmPassword
        }).then((res) => {
            if (res && !res.err) {
                enqueueSnackbar(t("ResetPasswordSuccess"), { variant: "success" });
                navigate(usersListUrl);
            } else {
                if (res && res.err && res.err.message) {
                    if (res.err.message === "Passwords do not match") {
                        enqueueSnackbar(t("PasswordMismatch"), { variant: "error" });
                    } else {
                        enqueueSnackbar(t("ResetPasswordError"), { variant: "error" });
                    }
                }
            }})
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ padding: "100px 32px" }}
        >
            <Grid item xs={12}>
                <CustomTitle title={t("resetPassword") + " - " + t("user") + " " + user} goBack={() => navigate(usersListUrl)} />
            </Grid>
            <Grid item xs={12} md={8}>
                <CustomCard
                    content={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    label={t("newPassword")}
                                    value={newPassword}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    label={t("confirmPassword")}
                                    value={confirmPassword}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                    }
                    actions={[
                        <CustomButton
                            fullWidth
                            label={t("reset")}
                            onClick={() => {
                                setPassword("")
                                setConfirmPassword("")
                            }}
                            confirm
                            type="outlined"
                        />,
                        <CustomButton
                            fullWidth
                            label={t("ok")}
                            onClick={handleSubmit}
                            type="contained"
                        />,
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default UpdatePassword;
