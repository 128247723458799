import { AddCraneRequest, UpdateCraneRequest, DeleteCraneRequest} from "../requests/craneService";
import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

import {
  createCraneUrl,
  getProfileUrl,
  getCraneUrl,
  getCranesUrl,
  updateCraneUrl,
  deleteCraneUrl,
} from "./url";

export const createCrane = (body: AddCraneRequest) => {
  return verticalFetch(methodPost, createCraneUrl, JSON.stringify(body));
};

export const getProfile = () => {
  return verticalFetch(methodGet, getProfileUrl);
};

export const getCrane = (uid: string) => {
  return verticalFetch(methodGet, getCraneUrl(uid));
};

export const getCranes = () => {
  return verticalFetch(methodGet, getCranesUrl);
};

export const updateCrane = (uid: string, body: UpdateCraneRequest) => {
  return verticalFetch(methodPut, updateCraneUrl(uid), JSON.stringify(body));
};

export const deleteCrane = (uid: string, body: DeleteCraneRequest) => {
  return verticalFetch(methodPut, deleteCraneUrl(uid), JSON.stringify(body));
};