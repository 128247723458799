import React, { useCallback, useEffect, useState } from "react";
import { recoverPassword } from "../../../api/services/loginService";
import { useLocation, useNavigate } from "react-router-dom";
import { recoverPageUrlResult } from "../costants";
import logo from "../../../images/logo_verticale.svg";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import { darkColor } from "../../custom/utils";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import CustomInput from "../../custom/CustomInput";

const RecoverPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  document.body.style.backgroundColor = darkColor;

  const [token, setToken] = useState<string | string[]>("");
  useEffect(() => {
    const queryString = window.location.search;
    const params = Object.fromEntries(new URLSearchParams(queryString));
    if (params["?token"]) {
      setToken(params["?token"]);
    } else {
      setToken("");
    }
  }, [location.search]);

  const [values, setValues] = useState<{
    password: string;
    confirmPassword: string;
  }>({ password: "", confirmPassword: "" });
  const submit = useCallback(() => {
    recoverPassword({
      password: values.password,
      confirm_password: values.confirmPassword,
      token: token,
    })
      .then((response) => {
        if (response.status === "success") {
          navigate(recoverPageUrlResult);
        } else {
          enqueueSnackbar(t("recoverPasswordError"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        console.log("error:", e);
      });
  }, [token, values]);

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <img
            src={logo}
            alt="logo"
            width="30%"
            style={{ marginLeft: "34%", marginRight: "34%" }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid item container flexDirection="column" spacing={2}>
                <Grid item>
                  <CustomText label={t("insertNewPassword")} type="h3" />
                </Grid>
                <Grid item>
                  <CustomInput
                    type="password"
                    label={t("password")}
                    value={values.password}
                    handleChange={(e) =>
                      setValues({ ...values, password: e.target.value })
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomInput
                    type="password"
                    label={t("confirmPassword")}
                    value={values.confirmPassword}
                    handleChange={(e) =>
                      setValues({ ...values, confirmPassword: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                type="contained"
                label={t("reset")}
                onClick={submit}
              />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecoverPassword;
