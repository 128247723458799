import {
  adminMenuItems,
  controllerMenuItems,
  managerMenuItems,
} from "./menuItems";
import React, { useEffect, useState } from "react";
import { getProfile } from "../../api/services/userService";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import { capitalizedString, darkColor } from "../custom/utils";
import { roles } from "../pages/costants";
import { Link } from "react-router-dom";

type NavbarProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  isMobile: boolean;
  configuratorRunning: boolean;
};

const Navbar: React.FC<NavbarProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  isMobile,
  configuratorRunning,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [panelOpen, setPanelOpen] = useState<boolean[]>([false]);
  const [menuItem, setMenuItem] = useState<any[]>([]);

  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        if (res.user.roles[0] === roles.admin) {
          setMenuItem(adminMenuItems(t));
        }
        if (res.user.roles[0] === roles.controller) {
          setMenuItem(controllerMenuItems(t));
        }
        if (res.user.roles[0] === roles.manager) {
          setMenuItem(managerMenuItems(t));
        }
      }
    });
  }, [i18n.language]);

  return (
    <Drawer
      onClose={() => SetNavbarIsCollapsed(true)}
      open={!navbar_collapsed}
      variant={!isMobile ? "persistent" : undefined}
    >
      <List
        style={{
          backgroundColor: darkColor,
          color: "white",
          paddingTop: "66px",
          width: "230px",
          height: "100%",
        }}
      >
        {menuItem.map((item: any, index: number) => {
          return (
            <div>
              <Link
                to={typeof item.link === "string" ? item.link : item.link()}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  if (isMobile) {
                    SetNavbarIsCollapsed(true);
                  }
                }}
              >
                <ListItem
                  button
                  key={item.key}
                  onClick={() => {
                    if (item?.link) {
                      if (typeof item.link === "string") {
                        navigate(item.link);
                      } else {
                        navigate(item.link());
                      }
                    }
                    if (item.elements.length > 0) {
                      let tmp = panelOpen;
                      tmp[index] = !tmp[index];
                      setPanelOpen([...tmp]);
                    } else {
                      setSelectedItem(item.key);
                      //SetNavbarIsCollapsed(true);
                    }
                  }}
                  selected={selectedItem === item.key}
                  disabled={configuratorRunning}
                >
                  <ListItemText
                    sx={{ fontSize: "14px" }}
                    primary={capitalizedString(item.label)}
                  />
                  {item.elements.length > 0 && (
                    <ExpandMore htmlColor="#455a64" />
                  )}
                </ListItem>
              </Link>
              {item.elements.length > 0 && (
                <Collapse in={panelOpen[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.elements.map((e: any) => (
                      <Link
                        to={typeof e.link === "string" ? e.link : e.link()}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <ListItem
                          disabled={configuratorRunning}
                          key={e.key}
                          selected={selectedItem === e.key}
                          button
                          onClick={() => {
                            if (e?.link) {
                              setSelectedItem(e.key);
                              if (typeof e.link === "string") {
                                navigate(e.link);
                              } else {
                                navigate(e.link());
                              }
                              if (isMobile) {
                                SetNavbarIsCollapsed(true);
                              }
                              //SetNavbarIsCollapsed(true);
                            }
                          }}
                        >
                          <ListItemText primary={capitalizedString(e.label)} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              )}
              <Divider style={{ border: "1px solid white" }} />
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Navbar;
