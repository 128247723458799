import { Chip } from "@mui/material";
import React from "react";
import { darkColor, lightColor } from "./utils";

type CustomTagProps = {
  label: string;
  type: "filled" | "outlined";
  onClick?: () => void;
  color?: string;
};

const CustomTag: React.FC<CustomTagProps> = ({
  label,
  type,
  onClick,
  color,
}) => {
  if (type === "filled") {
    return (
      <Chip
        label={label}
        variant={type}
        onClick={onClick ? onClick : undefined}
        style={{
          backgroundColor: color ? color : lightColor,
          color: "white",
        }}
      />
    );
  }
  if (type === "outlined") {
    return (
      <Chip
        label={label}
        variant={type}
        onClick={onClick ? onClick : undefined}
        style={{
          color: color ? color : darkColor,
          borderColor: color ? color : darkColor,
        }}
      />
    );
  }
  return null;
};

export default CustomTag;
