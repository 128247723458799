import { 
  AddDockModeRequest, 
  AddDockNumRequest, 
  UpdateDockModeRequest, 
  UpdateDockNumRequest,
  DeleteDockModeRequest,
  DeleteDockNumRequest,
} from "../requests/dockService";

import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

import {
    createDockModeUrl,
    createDockNumUrl,
    getDockModeUrl,
    getDockNumUrl,
    getDockModesUrl,
    getDockNumbersUrl,
    getDockInfoUrl,
    updateDockModeUrl,
    updateDockNumUrl,
    deleteDockModeUrl,
    deleteDockNumUrl,
} from "./url";

export const createDockMode = (body: AddDockModeRequest) => {
  return verticalFetch(methodPost, createDockModeUrl, JSON.stringify(body));
};

export const createDockNum = (body: AddDockNumRequest) => {
  return verticalFetch(methodPost, createDockNumUrl, JSON.stringify(body));
};

export const getDockMode = (uid: string) => {
  return verticalFetch(methodGet, getDockModeUrl(uid));
};

export const getDockNum = (uid: string) => {
  return verticalFetch(methodGet, getDockNumUrl(uid));
};

export const getDockModes = () => {
  return verticalFetch(methodGet, getDockModesUrl);
};

export const getDockNumbers = () => {
  return verticalFetch(methodGet, getDockNumbersUrl);
};

export const updateDockMode = (uid: string, body: UpdateDockModeRequest) => {
  return verticalFetch(methodPut, updateDockModeUrl(uid), JSON.stringify(body));
};

export const updateDockNum = (uid: string, body: UpdateDockNumRequest) => {
  return verticalFetch(methodPut, updateDockNumUrl(uid), JSON.stringify(body));
};

export const deleteDockMode = (uid: string, body: DeleteDockModeRequest) => {
  return verticalFetch(methodPut, deleteDockModeUrl(uid), JSON.stringify(body));
};

export const deleteDockNum = (uid: string, body: DeleteDockNumRequest) => {
  return verticalFetch(methodPut, deleteDockNumUrl(uid), JSON.stringify(body));
};

export const getDockInfo = () => {
  return verticalFetch(methodGet, getDockInfoUrl);
};