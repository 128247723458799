import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./components/pages/costants";
import RecoverPassword from "./components/pages/recoverPassword/RecoverPassword";
import ResultResetPassword from "./components/pages/resetPassword/Result";
import ResultRecoverPassword from "./components/pages/recoverPassword/Result";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginPanel from "./components/pages/login/Login";
import MainPage from "./components/pages/mainPage";
import ResetPassword from "./components/pages/resetPassword/ResetPassword";
import { CssBaseline } from "@mui/material/";
import { SnackbarProvider } from "notistack";
import { darkColor, greyColor, lightColor } from "./components/custom/utils";

declare global {
  interface Window {
    _env_: any;
    api: any;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  palette: {
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: { main: darkColor },
    mode: "light",
    text: {
      primary: darkColor,
      secondary: greyColor,
    },
    secondary: { main: lightColor },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: darkColor,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: darkColor,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: darkColor,
        },
      },
    },
  },
});

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path={routes.login} element={<LoginPanel />} />
            <Route path={routes.recoverStart} element={<ResetPassword />} />
            <Route path={routes.recoverEnd} element={<RecoverPassword />} />
            <Route
              path={routes.resetResult}
              element={<ResultResetPassword />}
            />
            <Route
              path={routes.recoverResult}
              element={<ResultRecoverPassword />}
            />
            <Route path={routes.mainPage} element={<MainPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
