import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, ordersListUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { createOrder as createOrderApi } from "../../../api/services/orderService";
import { useSnackbar } from "notistack";
import { AddOrderRequest } from "../../../api/requests/orderService";
import CustomSelect from "../../custom/CustomSelect";
import { getDockInfo } from "../../../api/services/dockService";

type CreateOrderProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const CreateOrder: React.FC<CreateOrderProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    order_id: "",
    description: "",
    dock_number: "",
    dock: "",
    status: "",
    total_mov: "",
    assigned_date: "",
  });
  const [dockNums, setDockNums] = useState<any[]>([]);
  const [dockModes, setDockModes] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getDockInfo().then((res: any) => {
      if (res && res.dock_info) {
        setDockNums([...res.dock_info.dock_nums]);
        setDockModes([...res.dock_info.dock_mode]);
      } else {
        setDockNums([]);
        setDockModes([]);
      }
      setLoading(false);
    });
  }, []);


  const handleSubmit = () => {
    if (!values.total_mov || parseInt(values.total_mov, 10) <= 0) {
      enqueueSnackbar(t("totalMovRequired"), { variant: "error" });
      return;
    }
    createOrderApi({
      order_id: values.order_id,
      description: values.description,
      dock_number: (values.dock_number).toString(),
      dock: (values.dock).toString(),
      status: values.status,
      total_mov: values.total_mov,
      assigned_date: values.assigned_date,
    } as AddOrderRequest).then((res) => {
      console.log(res);
      if (res && res.order) {
        setValues({
          order_id: "",
          description: "",
          dock_number: "",
          dock: "",
          status: "",
          total_mov: "",
          assigned_date: "",
        });
        enqueueSnackbar(t("orderCreatedSuccess"), { variant: "success" });
        navigate(ordersListUrl);
      } else {
        enqueueSnackbar(`${t("orderCreatedError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("orderId")}
                  value={values.order_id}
                  handleChange={(e) =>
                    setValues({ ...values, order_id: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("numDocks")}`}
                  value={values.dock_number}
                  options={dockNums.map((dockNum) => ({
                    value: dockNum.code,
                    label: dockNum.description,
                  }))}
                  handleChange={(e) =>
                    setValues({ ...values, dock_number: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("dockMode")}`}
                  value={values.dock}
                  options={dockModes.map((dockMode) => ({
                    value: dockMode.code,
                    label: dockMode.description,
                  }))}
                  handleChange={(e) =>
                    setValues({ ...values, dock: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("status")}`}
                  value={values.status}
                  options={[
                    { label: t("open"), value: "Open" },
                    { label: t("close"), value: "Closed" },
                  ]
                  }
                  handleChange={(e) =>
                    setValues({ ...values, status: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("totalMovements")}
                  value={values.total_mov}
                  handleChange={(e) =>
                    setValues({ ...values, total_mov: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("creationDate")}
                  type="date"
                  value={values.assigned_date}
                  handleChange={(e) =>
                    setValues({ ...values, assigned_date: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  order_id: "",
                  description: "",
                  dock_number: "",
                  dock: "",
                  status: "",
                  total_mov: "",
                  assigned_date: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateOrder;
