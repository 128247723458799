import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { dockNumListUrl, infoManagementUrl, mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { createDockNum } from "../../../api/services/dockService";
import { useSnackbar } from "notistack";
import { AddDockNumRequest } from "../../../api/requests/dockService";

type CreateDockNumProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const CreateDockNum: React.FC<CreateDockNumProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={infoManagementUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    code: "",
    description: "",
    notes: "",
  });

  const handleSubmit = () => {
    createDockNum({
      code: values.code,
      description: values.description,
      notes: values.notes,
      } as AddDockNumRequest).then((res) => {
      if (res && res.dock_num) {
        setValues({
          code: "",
          description: "",
          notes: "",
        });
        enqueueSnackbar(t("dockNumCreatedSuccess"), { variant: "success" });
        navigate(dockNumListUrl);
      } else {
        enqueueSnackbar(`${t("dockNumCreatedError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(infoManagementUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("notes")}
                  value={values.notes}
                  handleChange={(e) =>
                    setValues({ ...values, notes: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  code: "",
                  description: "",
                  notes: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateDockNum;
