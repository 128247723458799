import { 
    UpdateSettingsRequest, 
  } from "../requests/settingsService";
  
  import {
    methodGet,
    methodPost,
    verticalFetch,
  } from "./httpRequests";
  
  import {
    updateSettingsUrl,
    getSettingsUrl,
  } from "./url";
  
  export const getSettings = () =>{
    return verticalFetch(methodGet, getSettingsUrl);
  };
 
  export const updateSettings = (body: UpdateSettingsRequest) => {
    return verticalFetch(methodPost, updateSettingsUrl, JSON.stringify(body));
  };