import { 
    AddOrderRequest, 
    UpdateOrderRequest,
    DeleteOrderRequest
  } from "../requests/orderService";
  
  import {
    methodGet,
    methodPost,
    methodPut,
    verticalFetch,
  } from "./httpRequests";
  
  import {
    createOrderUrl,
    getProfileUrl,
    getOrderUrl,
    getAllOrdersUrl,
    getOrdersUrl,
    updateOrderUrl,
    deleteOrderUrl
  } from "./url";
  
  export const getOrders = () => {
    return verticalFetch(methodGet, getOrdersUrl);
  };

  export const getAllOrders = () => {
    return verticalFetch(methodGet, getAllOrdersUrl);
  };
  
  export const createOrder = (body: AddOrderRequest) => {
    return verticalFetch(methodPost, createOrderUrl, JSON.stringify(body));
  };
  
  export const getProfile = () => {
    return verticalFetch(methodGet, getProfileUrl);
  };
  
  export const getOrder = (oid: string) => {
    return verticalFetch(methodGet, getOrderUrl(oid));
  };
  
  export const updateOrder = (oid: string, body: UpdateOrderRequest) => {
    return verticalFetch(methodPut, updateOrderUrl(oid), JSON.stringify(body));
  };
  
  export const deleteOrder = (oid: string, body: DeleteOrderRequest) => {
    return verticalFetch(methodPut, deleteOrderUrl(oid), JSON.stringify(body));
  };