import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomTitle from "../../custom/CustomTitle";
import {
  PrecisionManufacturing,
  FormatListNumbered,
  DirectionsBoat,
  Engineering,
  Flag,
  BackupTableOutlined,
} from "@mui/icons-material";

import { routes } from "../costants";

import CustomButton from "../../custom/CustomButton";
import { lightColor } from "../../custom/utils";

type InfoManagementProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const InfoManagement: React.FC<InfoManagementProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Typography color="#ffffff" key="1">
        {t("home")}
      </Typography>,
    ]);
  }, []);

  const handleIconCards = () => {
    return [
      {
        img: (
          <PrecisionManufacturing
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("createCrane"), t("updateCrane")],
        link: [routes.createCrane, routes.cranesList],
      },
      {
        img: (
          <FormatListNumbered
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("createNumDock"), t("updateNumDock")],
        link: [routes.createDockNum, routes.dockNumList],
      },
      {
        img: (
          <DirectionsBoat
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("createDockMode"), t("updateDockMode")],
        link: [routes.createDockMode, routes.dockModeList],
      },
      {
        img: (
          <Engineering
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("createCraneOperator"), t("updateCraneOperator")],
        link: [routes.createCraneOperator, routes.craneOperatorsList],
      },
      {
        img: (
          <Flag
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("createSignaler"), t("updateSignaler")],
        link: [routes.createSignaler, routes.signalersList],
      },
      {
        img: (
          <BackupTableOutlined
            style={{
              color: lightColor,
              fontSize: "159px",
            }}
          />
        ),
        text: [t("infoShifts")],
        link: [routes.shifts],
      },
    ];
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("infoManagement")}
          goBack={() => navigate(routes.mainPage)}
        />
      </Grid>
      {handleIconCards().map((iconItem) => (
        <Grid item xs={12} md={6}>
          <CustomCard
            content={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>{iconItem.img}</Grid>
              </Grid>
            }
            actions={iconItem.text.map(
              (buttonText: string, buttonIndex: number) => (
                <CustomButton
                  fullWidth
                  type="contained"
                  label={buttonText}
                  onClick={() => navigate(iconItem.link[buttonIndex])}
                />
              )
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoManagement;
