import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dashboardManagerUrl } from "../costants";
import { getDashboardManager } from "../../../api/services/dashboardService";
import CustomLoading from "../../custom/CustomLoading";
import CustomCard from "../../custom/CustomCard";
import CustomList from "../../custom/CustomList";
import { ListElement } from "../../custom/types";
import CustomDivider from "../../custom/CustomDivider";
import CustomInput from "../../custom/CustomInput";
import { SearchOutlined } from "@mui/icons-material";

type DashboardManagerMobileProps = {
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardManagerMobile: React.FC<DashboardManagerMobileProps> = ({
  setNavbarCollapsed,
  setHeaderCollapsed,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const batch_status = searchParams.get("batch_status");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const crane_descr = searchParams.get("crane_descr");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  const [filters, setFilters] = useState<ListElement[]>([]);
  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    let tmpFilters: ListElement[] = [];
    if (batch_id) {
      tmpFilters.push({
        primaryText: `${t("order")}`,
        action: batch_id.split("-")[0],
      });
    }
    if (shift_id) {
      tmpFilters.push({ primaryText: `${t("shift")}`, action: shift_id });
    }
    if (crane_id) {
      tmpFilters.push({ primaryText: `${t("crane")}`, action: crane_descr });
    }
    if (start) {
      tmpFilters.push({ primaryText: `${t("startDate")}`, action: start });
    }
    if (end) {
      tmpFilters.push({ primaryText: `${t("endDate")}`, action: end });
    }
    setFilters(tmpFilters);
    return () => {
      setHeaderCollapsed(false);
    };
  }, [batch_id, shift_id, crane_id, batch_status, start, end]);

  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dashboard, setDashboardManager] = useState<any[]>([]);
  useEffect(() => {
    getDashboardManager(
      batch_id?.split(" - ")[0] || "",
      batch_status!,
      shift_id || "",
      crane_id || "",
      start || "",
      end || ""
    ).then((res: any) => {
      if (res && res.dashboard) {
        setDashboardManager([...res.dashboard]);
      } else {
        setDashboardManager([]);
      }
      setLoading(false);
    });
    setInterval(() => {
      getDashboardManager(
        batch_id?.split(" - ")[0] || "",
        batch_status!,
        shift_id || "",
        crane_id || "",
        start || "",
        end || ""
      ).then((res: any) => {
        if (res && res.dashboard) {
          setDashboardManager([...res.dashboard]);
        } else {
          setDashboardManager([]);
        }
        setLoading(false);
      });
    }, 60000);
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={
            batch_status === "true"
              ? `${t("openBatches")}`
              : `${t("closedBatches")}`
          }
          goBack={() =>
            navigate(
              dashboardManagerUrl +
                `?batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CustomList dense listElement={[...filters]} />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label={`${t("search")}`}
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
          endAdornment={<SearchOutlined />}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomCard
          content={
            <>
              {dashboard
                .filter((f) => {
                  //filter based on search value (no case sensitive) on all fields
                  let found = false;
                  Object.keys(f).forEach((key) => {
                    if (
                      f[key] &&
                      f[key]
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      found = true;
                    }
                  });
                  return found;
                })
                .map((d, index) => {
                  let tmp = [];
                  if (d.vessel)
                    tmp.push({
                      primaryText: `${t("vessel")}`,
                      secondaryText: d.vessel,
                    });
                  if (d.num_dock)
                    tmp.push({
                      primaryText: `${t("numDocks")}`,
                      secondaryText: d.num_dock,
                    });
                  if (d.total_movements)
                    tmp.push({
                      primaryText: `${t("totalMovements")}`,
                      secondaryText: d.total_movements,
                    });
                  if (d.moves_done)
                    tmp.push({
                      primaryText: `${t("movesDone")}`,
                      secondaryText: d.moves_done,
                    });
                  if (d.moves_to_do)
                    tmp.push({
                      primaryText: `${t("movesToDo")}`,
                      secondaryText: d.moves_to_do,
                    });
                  if (d.percentage)
                    tmp.push({
                      primaryText: `${t("percentage")}`,
                      secondaryText: d.percentage + "%",
                    });
                  if (d.vessel_fl)
                    tmp.push({
                      primaryText: `${t("vesselFL")}`,
                      secondaryText:
                        d.vessel_fl !== "0001-01-01T00:00:00Z"
                          ? d.vessel_fl.split("T")[0] +
                            " " +
                            d.vessel_fl.split("T")[1].split("Z")[0]
                          : "N.A.",
                    });
                  if (d.vessel_ll)
                    tmp.push({
                      primaryText: `${t("vesselLL")}`,
                      secondaryText:
                        d.vessel_ll !== "0001-01-01T00:00:00Z"
                          ? d.vessel_ll.split("T")[0] +
                            " " +
                            d.vessel_ll.split("T")[1].split("Z")[0]
                          : "N.A.",
                    });
                  if (d.working_time)
                    tmp.push({
                      primaryText: `${t("workingTime")}`,
                      secondaryText: d.working_time,
                    });
                  if (d.gmph)
                    tmp.push({
                      primaryText: `${t("gmph")}`,
                      secondaryText: d.gmph,
                    });
                  return (
                    <>
                      <CustomList dense listElement={[...tmp]} />
                      <CustomDivider />
                    </>
                  );
                })}
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default DashboardManagerMobile;
