import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { loginPageUrl, resetPasswordPageUrlResult } from "../costants";
import logo from "../../../images/logo_verticale.jpeg";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import { darkColor } from "../../custom/utils";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomText from "../../custom/CustomText";

type ResetPanelProps = {};

const ResetPassword: React.FC<ResetPanelProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");

  document.body.style.backgroundColor = darkColor;

  const submit = () => {
    resetPassword({ email: email }).then((res) => {
      if (res && !res.err) {
        navigate(resetPasswordPageUrlResult);
      }
    });
  };

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <img
                    src={logo}
                    alt="logo"
                    width="15%"
                    style={{ marginLeft: "42%", marginRight: "42%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomText label={t("resetPassword")} type="h3" />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    label={t("email")}
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                type="outlined"
                label={t("goBack")}
                onClick={() => navigate(loginPageUrl)}
              />,
              <CustomButton
                fullWidth
                type="contained"
                label={t("reset")}
                onClick={submit}
              />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
