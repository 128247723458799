import { FormHelperText, InputAdornment, TextField } from "@mui/material";
import React, { ReactNode } from "react";

type CustomInputProps = {
  label: string;
  value: string | number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  validator?: {
    error?: boolean;
    helperText?: string;
  };
  multiline?: number;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
};

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  type,
  value,
  handleChange,
  validator,
  multiline,
  startAdornment,
  endAdornment,
  disabled,
}) => {
  return (
    <TextField
      fullWidth
      value={value}
      label={label}
      variant="outlined"
      onChange={handleChange}
      disabled={disabled}
      type={type ? type : "text"}
      error={validator && validator.error && validator.error}
      helperText={
        validator &&
        validator.error && (
          <FormHelperText error>{validator?.helperText || ""}</FormHelperText>
        )
      }
      multiline={multiline ? true : undefined}
      rows={multiline && multiline}
      InputLabelProps={{
        shrink: type === "date" ? true : undefined,
      }}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment} </InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment} </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

export default CustomInput;
